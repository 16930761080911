









import { Component, Vue } from 'vue-property-decorator';

import TheGeoMarkerIcon from '@/components/icons/TheGeoMarkerIcon.vue';

@Component({
  components: { TheGeoMarkerIcon },
})
export default class FindOnMapAction extends Vue {}
