<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="25"
    height="32"
    viewBox="0 0 25 32"
  >
    <defs>
      <path
        id="cfoia"
        d="M914.5 1111.27a4.26 4.26 0 0 1-4.28-4.23 4.26 4.26 0 0 1 4.28-4.22c2.37 0 4.3 1.9 4.3 4.22a4.26 4.26 0 0 1-4.3 4.23zm0-9.36c-2.87 0-5.2 2.3-5.2 5.13a5.17 5.17 0 0 0 5.2 5.14c2.88 0 5.21-2.3 5.21-5.14a5.18 5.18 0 0 0-5.2-5.13z"
      ></path>
      <path
        id="cfoib"
        d="M914.5 1124.84c-1.84-2.24-10.29-12.76-10.29-17.8 0-5.59 4.62-10.14 10.3-10.14 5.67 0 10.29 4.55 10.29 10.15 0 5.03-8.45 15.55-10.3 17.79zm0-28.84c-6.18 0-11.2 4.96-11.2 11.05 0 5.95 10.4 18.27 10.85 18.79a.46.46 0 0 0 .7 0c.45-.52 10.86-12.85 10.86-18.8 0-6.08-5.03-11.04-11.2-11.04z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-902 -1095)">
        <use fill="#363636" xlink:href="#cfoia"></use>
        <use
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-miterlimit="50"
          stroke-width=".5"
          xlink:href="#cfoia"
        ></use>
      </g>
      <g transform="translate(-902 -1095)">
        <use fill="#363636" xlink:href="#cfoib"></use>
        <use
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-miterlimit="50"
          stroke-width=".5"
          xlink:href="#cfoib"
        ></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheGeoMarkerIcon',
};
</script>
